<template>
    <div>
      <v-container fluid>
        <base-material-card
          icon="mdi-clipboard-text"
          title="1.2 การปฏิบัติหน้าที่ในวิชาเอก/เกี่ยวข้องกับการจัดการเรียนการสอน/ผู้บังคับบัญชามอบหมาย"
          class="px-5 py-3"
        >
          <v-card class="mb-4 pa-2">
            <v-row>
              <v-col cols="12" lg="6" class="text-right">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />
              </v-col>
              <v-col cols="12" lg="6" class="text-right">
                <v-btn
                  large
                  right
                  depressed
                  color="primary"
                  @click.native="personnel_dutyAdd()"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
  
          <v-data-table
            color="success"
            :loading="loading"
            :headers="headers"
            :items="personnel_dutys"
            :search="search"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">{{ item.college_name }}</td>
                <td class="text-center">{{ item.personnel_dutyYear }}</td>
                <td>{{ item.personnel_dutyOrderID }}</td>
                <td>{{ item.personnel_dutyTopic }}</td>              
                <td class="text-center">{{ item.personnel_dutyDateTime | moment("add", "543 years")
                      | moment("D MMMM YYYY") }}</td>                
                <td class="text-center">
                  <v-icon
                    color="yellow"
                    @click.stop="personnel_dutyEdit(item.personnel_dutyID)"
                  >
                    mdi-pencil
                  </v-icon>
                </td>
                <td class="text-center">
                  <v-icon
                    color="red"
                    @click.stop="personnel_dutyDelete(item.personnel_dutyID)"
                  >
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
            </template>
  
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
              >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
            >
          </v-data-table>
        </base-material-card>
  
        <!--addpersonnel_dutydialog  -->
        <v-layout row justify-center>
          <v-dialog
            v-model="addpersonnel_dutydialog"
            persistent
            max-width="50%"
            overlay-opacity="0.6"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                icon="mdi-clipboard-text"
                title="เพิ่มข้อมูล"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text class="text_google">
                <v-form ref="addpersonnel_dutyform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex md12>
                        <v-row>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              v-model="addpersonnel_duty.personnel_dutyYear"
                              prepend-icon="mdi-calendar"
                              dense
                              label="ปี : 2565"
                              request
                              :rules="[
                                v => !!v || '',
                                v => (v && v.length <= 4) || ''
                              ]"
                              rounded
                              type="number"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              v-model="addpersonnel_duty.personnel_dutyOrderID"
                              prepend-icon="mdi-access-point"
                              dense
                              label="คำสั่งที่ : 888/2565"
                              request
                              :rules="[v => !!v || '']"
                              rounded
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="12">
                            <v-textarea
                              v-model="addpersonnel_duty.personnel_dutyTopic"
                              label="ชื่อโครงการ/กิจกรรมในสถานศึกษา"
                              prepend-icon="mdi-details"
                              dense
                              :rules="[v => !!v || '']"
                              rounded
                              outlined
                            ></v-textarea>
                          </v-col>
                                           
                          <v-col cols="12" lg="12">
                            <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    addpersonnel_duty.personnel_dutyDateTime
                                  "
                                  label="วันที่เริ่ม"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  rounded
                              outlined
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="addpersonnel_duty.personnel_dutyDateTime"
                                no-title
                                scrollable
                                locale="TH-th"
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>                   
                        </v-row>
                      </v-flex>
                    </v-layout>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col cols="12" lg="12" class="text-right">
                        <v-btn
                          large
                          color="success"
                          @click.stop="addpersonnel_dutySubmit()"
                          rounded
                        >
                          <v-icon dark>mdi-content-save</v-icon
                          >&nbsp;&nbsp;บันทึก</v-btn
                        >
                        <v-btn
                          large
                          color="warning"
                          @click.stop="addpersonnel_dutydialog = false"
                          rounded
                        >
                          <v-icon dark>mdi-close</v-icon>ยกเลิก
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model deletepersonnel_dutydialog -->
        <v-layout>
          <v-dialog
            v-model="deletepersonnel_dutydialog"
            persistent
            max-width="40%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="error"
                icon="mdi-delete"
                title="ลบข้อมูล"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text class="text_google">
                <v-form ref="deletepersonnel_dutyform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12>
                        ยืนยันการลบข้อมูล :
                        <h3>คำสั่งที่ {{ editpersonnel_duty.personnel_dutyOrderID }}</h3>
                        <h4>เรื่อง {{ editpersonnel_duty.personnel_dutyTopic }}</h4>
                      </v-flex>
                      <v-flex xs12 md6></v-flex>
                      <v-flex xs12 md6></v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="deletepersonnel_dutydialog = false"
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="red darken-3"
                  @click.stop="deletepersonnel_dutyubmit()"
                  dark
                >
                  <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
  
        <!-- V-model editpersonnel_dutydialog -->
        <v-layout row justify-center>
          <v-dialog
            v-model="editpersonnel_dutydialog"
            persistent
            max-width="50%"
          >
            <v-card class="mx-auto pa-5">
              <base-material-card
                color="yellow"
                icon="mdi-clipboard-text"
                title="แก้ไขข้อมูลการศึกษา"
                class="px-5 py-3 text_google"
              ></base-material-card>
              <v-card-text>
                <v-form ref="editpersonnel_dutyform" lazy-validation>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex md12>                      
                        <v-row>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              v-model="editpersonnel_duty.personnel_dutyYear"
                              prepend-icon="mdi-calendar"
                              dense
                              label="ปี : 2565"
                              request
                              :rules="[
                                v => !!v || '',
                                v => (v && v.length <= 4) || ''
                              ]"
                              rounded
                              type="number"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              v-model="editpersonnel_duty.personnel_dutyOrderID"
                              prepend-icon="mdi-access-point"
                              dense
                              label="คำสั่งที่ : 888/2565"
                              request
                              :rules="[v => !!v || '']"
                              rounded
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="12">
                            <v-textarea
                              v-model="editpersonnel_duty.personnel_dutyTopic"
                              label="ชื่อโครงการ/กิจกรรมในสถานศึกษา"
                              prepend-icon="mdi-details"
                              dense
                              :rules="[v => !!v || '']"
                              rounded
                              outlined
                            ></v-textarea>
                          </v-col>
                                           
                          <v-col cols="12" lg="12">
                            <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="
                                    editpersonnel_duty.personnel_dutyDateTime
                                  "
                                  label="วันที่เริ่ม"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  rounded
                              outlined
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="editpersonnel_duty.personnel_dutyDateTime"
                                no-title
                                scrollable
                                locale="TH-th"
                              >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu2 = false">
                                  Cancel
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu2.save(date)"
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-col>                   
                        </v-row>

                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  large
                  @click.stop="editpersonnel_dutydialog = false"
                  rounded
                >
                  <v-icon dark>mdi-close</v-icon>ยกเลิก
                </v-btn>
                <v-btn
                  large
                  color="warning"
                  @click.stop="editpersonnel_dutySubmit()"
                  rounded
                >
                  <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-container>
      <v-container fluid>
        <v-snackbar
          v-model="snackbar.show"
          multi-line
          vertical
          top
          :timeout="snackbar.timeout"
          :color="snackbar.color"
        >
          <v-icon large>{{ snackbar.icon }}</v-icon>
          <br />
          {{ snackbar.text }}
          <v-btn dark @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
      </v-container>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        ApiKey: "HRvec2021",
        loading: true,
        updateImageDialog: false,
        search: "",
        addpersonnel_dutydialog: false,
        editpersonnel_dutydialog: false,
        deletepersonnel_dutydialog: false,
        showimagedialog: false,
        snackbar: {
          show: false,
          color: "",
          timeout: 5000,
          icon: "",
          text: ""
        },
        currentPK: null,
        headers: [
          { text: "#", align: "center", value: "index" },
          { text: "สถานศึกษา", align: "center", value: "personnel_dutyCollege" },
          { text: "ปี", align: "left", value: "personnel_dutyYear" },
          { text: "คำสั่งที่", align: "left", value: "personnel_dutyOrderID" },
          { text: "ชื่อโครงการ/กิจกรรมในสถานศึกษา", align: "left", value: "personnel_dutyTopic" },
          { text: "วันที่", align: "center", value: "personnel_dutyDateTime" },         
          {
            text: "แก้ไข",
            align: "center",
            value: "actions",
            icon: "mdi-file-document-edit"
          },
          {
            text: "ลบ",
            align: "center",
            value: "action_s",
            icon: "mdi-delete-forever"
          }
        ],
        rowsperpage: [
          25,
          50,
          100,
          {
            text: "All",
            value: -1
          }
        ],
        pagination: {},
        personnel_duty: [],
        addpersonnel_duty: {},
        editpersonnel_duty: [],
        personnel_dutys: [],
        personnel_duty_sub: [],
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        menu: false,
        menu2: false,
        menu3: false,
        menu4: false
      };
    },
  
    async mounted() {
      this.personnel_dutysQueryAll();
    },
  
    methods: {
      async personnel_dutysQueryAll() {
        this.loading = true;
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        let result = await this.$http
          .post("personnel_duty.php", {
            ApiKey: this.ApiKey,
            personnel_dutyIDCard: userSession.id_card
          })
          .finally(() => (this.loading = false));
        this.personnel_dutys = result.data;
        
      },
      //Add data
      async personnel_dutyAdd() {
        this.addpersonnel_duty = {};
        this.addpersonnel_dutydialog = true;
      },
  
      async addpersonnel_dutySubmit() {
        if (this.$refs.addpersonnel_dutyform.validate()) {
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.addpersonnel_duty.ApiKey = this.ApiKey;
          this.addpersonnel_duty.personnel_dutyIDCard = userSession.id_card;
          this.addpersonnel_duty.personnel_dutyCollege = userSession.college_code;
          let result = await this.$http.post(
            "personnel_duty.insert.php",
            this.addpersonnel_duty
          );
          
          if (result.data.status == true) {
            this.personnel_duty = result.data;
             Swal.fire({
              icon: 'success',
              title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
              showConfirmButton: false,
              timer: 1500,
            })
            this.personnel_dutysQueryAll();
          } else {
            Swal.fire({
                icon: "warning",
                title: "ดำเนินการผิดพลาด",
                showConfirmButton: false,
                timer: 1500
              });
          }
          this.addpersonnel_dutydialog = false;
        }
      },
  
      //Edit data
      async personnel_dutyEdit(personnel_dutyID) {
        let result = await this.$http.post("personnel_duty.php", {
          ApiKey: this.ApiKey,
          personnel_dutyID: personnel_dutyID
        });
        this.editpersonnel_duty = result.data;
        this.editpersonnel_dutydialog = true;
      },
  
      async editpersonnel_dutySubmit() {
        if (this.$refs.editpersonnel_dutyform.validate()) {
          this.editpersonnel_duty.ApiKey = this.ApiKey;
          let result = await this.$http.post(
            "personnel_duty.update.php",
            this.editpersonnel_duty
          );
          if (result.data.status == true) {
            this.personnel_duty = result.data;
             Swal.fire({
            icon: 'success',
            title: 'แก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
            this.personnel_dutysQueryAll();
          } else {
             Swal.fire({
            icon: 'warning',
            title: 'แก้ไขข้อมูลผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
          }
          this.editpersonnel_dutydialog = false;
        }
      },
  
      //DELETE data
      async personnel_dutyDelete(personnel_dutyID) {
        let result = await this.$http.post("personnel_duty.php", {
          ApiKey: this.ApiKey,
          personnel_dutyID: personnel_dutyID
        });
        this.editpersonnel_duty = result.data;
        this.deletepersonnel_dutydialog = true;
      },
  
      async deletepersonnel_dutyubmit() {
        if (this.$refs.deletepersonnel_dutyform.validate())
          this.editpersonnel_duty.ApiKey = this.ApiKey;
        {
          let result = await this.$http.post(
            "personnel_duty.delete.php",
            this.editpersonnel_duty
          );
          if (result.data.status == true) {
            Swal.fire({
            icon: 'success',
            title: 'ลบข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
            this.personnel_dutysQueryAll();
          } else {
            Swal.fire({
            icon: 'warning',
            title: 'ลบข้อมูลผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
          }
          this.deletepersonnel_dutydialog = false;
        }
      }
    },
  
    computed: {
      pages() {
        if (
          this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        )
          return 0;
  
        return Math.ceil(
          this.pagination.totalItems / this.pagination.rowsPerPage
        );
      }
    }
  };
  </script>
  <style>
  .v-data-table thead th {
    font-size: 16px !important;
  }
  </style>
  